<template>
  <div>
    <div v-if="messages.length" class="fixed top-0 w-full z-10">
      <div class="area">
        <div
          class=" bg-white mt-20 p-4 rounded-lg border-tt-yellow border-2 flex justify-between items-center"
        >
          <ul>
            <li v-for="(message, index) in messages" :key="index">
              {{ message }}
            </li>
          </ul>
          <TtButton
            @clicked="messages = []"
            color="transparent"
            icon="checkmark-outline"
            >Okay</TtButton
          >
        </div>
      </div>
    </div>
    <div class="area py-16">
      <h1 class="text-4xl">Contact</h1>
      <form
        id="contact"
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        class="mt-8 space-y-4 static"
        @submit.prevent="handleSubmit"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div
          class="flex sm:space-x-4 space-y-4 sm:space-y-0 flex-col sm:flex-row "
        >
          <p>
            <label class="block mb-2" for="name">Your Name: </label>
            <input
              class="px-4 py-2 bg-gray-200 rounded-lg"
              type="text"
              placeholder="Jane Doe"
              name="name"
              v-model="form.name"
            />
          </p>
          <p>
            <label class="block mb-2" for="name">Your Email: </label>
            <input
              class="px-4 py-2 bg-gray-200 rounded-lg"
              type="email"
              placeholder="example@mail.com"
              name="email"
              v-model="form.email"
            />
          </p>
        </div>
        <p>
          <label class="block mb-2" for="message">Message: </label>
          <textarea
            class="bg-gray-200 rounded-lg px-4 py-2 h-64 w-full resize-none"
            name="message"
            placeholder="What a cool app, I would have another idea...."
            v-model="form.message"
          ></textarea>
        </p>
        <button
          type="submit"
          class="py-2 px-6 inline-flex items-center rounded-lg cursor-pointer text-black focus:outline-none bg-tt-yellow"
          v-wave="{
            color: '#FFF2CC',
            duration: 0.3,
            initialOpacity: 0.5,
            easing: 'ease-in'
          }"
        >
          <ion-icon size="small" name="send-outline"></ion-icon>
          <p class="ml-4 font-bold">Send Message</p>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        text: ""
      },
      messages: []
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit() {
      if (!this.form.email || !this.form.name || !this.form.message) {
        this.messages.push("Please fill in all fields.");
        return;
      }

      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      axios
        .post(
          "/",
          this.encode({
            "form-name": "contact",
            ...this.form
          }),
          axiosConfig
        )
        .then(() => {
          this.messages.push(
            "Thank you for your message. We will contact you as soon as possible."
          );
        })
        .catch(error => {
          this.messages.push(error);
        });
    }
  }
};
</script>

<style></style>
